import React, { ChangeEvent, useState } from "react";

import Modal from "@material-ui/core/Modal";

import { Button, ButtonGroup, FormControl, InputLabel, TextField, Select, MenuItem } from "@material-ui/core";
import styled from "styled-components";

import ModalContainer from "../Containers/ModalContainer";
import subscriptionsAPI from "../../utils/api/v2/subscriptions";
import LoadingError from "../Loaders/LoadingError";
import { FREQUENCY_TRANSLATED } from "../../utils/constants/subscriptions";

const FrequencySelect = styled(Select)`
  width: 200px;
`;

type ItemType = {
  subcription_item_id: string;
  frequency: string;
  period_uses: number;
  current_period_uses: number;
}

type EditServiceModalProps = {
  isOpen: boolean;
  closeModal: (reload?: boolean) => void;
  item: any;
  subscriptionId: string;
}

const EditServiceModal = ({ isOpen, closeModal, item, subscriptionId }: EditServiceModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [changes, setChanges] = useState<ItemType>({
    subscription_item_id: item.id,
    frequency: item.period,
    period_uses: item.period_uses,
    current_period_uses: item.current_period_uses,
  })

  const handleEdit = async () => {
    setLoading(true);
    try {
      const req = await subscriptionsAPI.editSubscriptionItem(subscriptionId, changes);
      closeModal(true);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }

  const frequencyOptions =
    <div>
      <FormControl margin="normal">
        <InputLabel>Categoría</InputLabel>
        <FrequencySelect
          autoWidth
          value={changes.frequency}
          onChange={(e) => {
            setChanges({
              ...changes,
              frequency: e.target.value as string,
            });
          }}
        >
          {Object.keys(FREQUENCY_TRANSLATED).map((key: string) => (
            <MenuItem value={key} key={key}>
              {FREQUENCY_TRANSLATED[key]}
            </MenuItem>
          ))}
        </FrequencySelect>
      </FormControl>
    </div>

  function handlerOnChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setChanges({...changes, [e.target.name]: e.target.value.toLowerCase().trim()});
}

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        closeModal();
        document.getElementById("navbar").style.zIndex = "1999";
      }}
      style={{ height: "100%" }}
    >
      <ModalContainer>
        <LoadingError loading={loading} error={error} />
        {!loading && (
        <div className="flex flex-col gap-y-2">
          <div className="text-xl mb-2">Editar servicio para esta suscripción</div>
          {frequencyOptions}
          <TextField
            fullWidth
            type="number"
            label="Cantidad"
            variant="standard"
            onChange={handlerOnChange}
            name="period_uses"
            className="w-56"
            value={changes.period_uses}
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
          <TextField
            fullWidth
            type="number"
            label="Utilizados"
            variant="standard"
            onChange={handlerOnChange}
            name="current_period_uses"
            className="w-56"
            value={changes.current_period_uses}
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
          <div className="flex flex-row justify-between mt-3" >
            <ButtonGroup variant="outlined" color="primary" >
              <Button onClick={handleEdit}>Editar</Button>
            </ButtonGroup>
            <ButtonGroup variant="outlined" color="primary" >
              <Button onClick={() => closeModal()}>Cancelar</Button>
            </ButtonGroup>
          </div>
        </div>
        )}
      </ModalContainer>
    </Modal>
  )
}

export default EditServiceModal;
