import React, { ChangeEvent, useState } from "react";

import Modal from "@material-ui/core/Modal";

import { Button, ButtonGroup, FormControl, InputLabel, TextField, Select, MenuItem } from "@material-ui/core";
import styled from "styled-components";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import ModalContainer from "../Containers/ModalContainer";
import subscriptionsAPI from "../../utils/api/v2/subscriptions";
import LoadingError from "../Loaders/LoadingError";
import { FREQUENCY_TRANSLATED } from "../../utils/constants/subscriptions";
import Subscription from "../../utils/interfaces/Subscriptions";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const FrequencySelect = styled(Select)`
  width: 200px;
`;

const SAddCircleIcon = styled(AddCircleIcon)`
  margin-left: 15px;
  cursor: pointer;
`;

type ItemType = {
  billing_date: string;
  billing_frequency: string;
  base_price: number;
  monthly_discounts: number[];
}

type EditSubscriptionModalProps = {
  isOpen: boolean;
  closeModal: (reload?: boolean) => void;
  subscription: Subscription;
  subscriptionId: string;
}

const EditSubscriptionModal = ({ isOpen, closeModal, subscription, subscriptionId }: EditSubscriptionModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [changes, setChanges] = useState<ItemType>({
    base_price: subscription.base_price,
    billing_date: subscription.billing_date,
    billing_frequency: subscription.billing_frequency,
    monthly_discounts: subscription.monthly_discounts,
  })

  const handleEdit = async () => {
    setLoading(true);

    try {
      const req = await subscriptionsAPI.editSubscription(subscriptionId, changes);
      closeModal(true);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }

  const frequencyOptions = (): JSX.Element => {
    return (
      <div>
        <FormControl margin="normal">
          <InputLabel>Frecuencia de facturación</InputLabel>
          <FrequencySelect
            autoWidth
            value={changes.billing_frequency}
            onChange={(e) => {
              setChanges({
                ...changes,
                billing_frequency: e.target.value as string,
              });
            }}
          >
            {Object.keys(FREQUENCY_TRANSLATED).map((key: string) => (
              <MenuItem value={key} key={key}>
                {FREQUENCY_TRANSLATED[key]}
              </MenuItem>
            ))}
          </FrequencySelect>
        </FormControl>
      </div>
    );
  };

  function handleChangeMonthlyDiscount(month: number, value: number) {
    let newDiscounts = changes.monthly_discounts;
    newDiscounts[month] = value;
    setChanges({...changes, monthly_discounts: newDiscounts});
  }

  function addDiscount() {
    let newDiscounts = changes.monthly_discounts;
    newDiscounts.push(1);
    setChanges({...changes, monthly_discounts: newDiscounts});
  }

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        closeModal();
        document.getElementById("navbar").style.zIndex = "1999";
      }}
      style={{ height: "100%" }}
    >
      <ModalContainer>
        <LoadingError loading={loading} error={error} />
        {!loading && (
        <div className="flex flex-col gap-y-2">
          <div className="text-xl mb-2">Editar suscripción</div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              variant="inline"
              format="LL"
              margin="normal"
              label="Fecha de facturación"
              value={changes.billing_date}
              autoOk
              onChange={(date: moment.Moment) => {setChanges({...changes, billing_date: date.format("YYYY-MM-DD")})}}
            />
          </MuiPickersUtilsProvider>
          {frequencyOptions()}
          <TextField
            fullWidth
            type="number"
            label="Precio a cobrar"
            variant="standard"
            onChange={(e) => setChanges({...changes, base_price: parseInt(e.target.value)})}
            className="w-56"
            value={changes.base_price}
            InputProps={{
              inputProps: { min: 0 }
            }}
          />
          {changes.monthly_discounts.length > 0 && (
            <div className="mt-4">
              {changes.monthly_discounts.map((discount, number) => (
                <div className="-my-1">
                  <span className="font-bold">{number+1}° mes: </span>
                  <TextField
                    fullWidth
                    type="number"
                    label="Descuento"
                    variant="standard"
                    onChange={(e) => handleChangeMonthlyDiscount(number, parseInt(e.target.value))}
                    className="w-56"
                    value={changes.monthly_discounts[number]}
                    InputProps={{
                      inputProps: { min: 0 }
                    }}
                  />
                </div>
              ))}
            </div>
          )}
          <span>Descuentos por meses: </span>
          <SAddCircleIcon onClick={addDiscount} />
          <div className="flex flex-row justify-between mt-3" >
            <ButtonGroup variant="outlined" color="primary" >
              <Button onClick={handleEdit}>Editar</Button>
            </ButtonGroup>
            <ButtonGroup variant="outlined" color="primary" >
              <Button onClick={() => closeModal()}>Cancelar</Button>
            </ButtonGroup>
          </div>
        </div>
        )}
      </ModalContainer>
    </Modal>
  )
}

export default EditSubscriptionModal;
