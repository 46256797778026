import React, { useState } from "react"

import styled from "styled-components";
import Button from "@material-ui/core/Button";

import { FREQUENCY_TRANSLATED } from "../../utils/constants/subscriptions";
import { ButtonGroup } from "@material-ui/core";
import DeleteServiceModal from "./DeleteServiceModal";
import EditServiceModal from "./EditServiceModal";

const Content = styled.div`
  background-color: whitesmoke;
  border-radius: 1rem;
  padding: 1rem;
  display: inline-block;
  padding: 1em 2em 1em 1em;
  text-align: left;

  p {
    margin: 0.5rem;
    font-weight: 100;
  }

  h3 {
    margin-right: 2rem;
  }
`;

type IncludedServiceType = {
  item: any;
  subscriptionId: string;
  refetch: () => void;
}

const IncludedService = (props: IncludedServiceType) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const item = props.item;

  const handleCloseDeleteModal = (reload?: boolean) => {
    setIsDeleteModalOpen(false);
    if (reload){
      props.refetch();
    }
  }
  const handleCloseEditModal = (reload?: boolean) => {
    setIsEditModalOpen(false);
    if (reload){
      props.refetch();
    }
  }

  return (
    <Content>
      <DeleteServiceModal
        isOpen={isDeleteModalOpen}
        closeModal={handleCloseDeleteModal}
        item={item}
        subscriptionId={props.subscriptionId}
      />
      <EditServiceModal
        isOpen={isEditModalOpen}
        closeModal={handleCloseEditModal}
        item={item}
        subscriptionId={props.subscriptionId}
      />
      <div className="flex flex-col gap-y-1">
        <div>
          <span className="font-bold">Nombre: </span>
          {item.item.name}
        </div>
        <div>
          <span className="font-bold">Cantidad {FREQUENCY_TRANSLATED[item.period]}: </span>
          {item.period_uses}
        </div>
        <div>
          <span className="font-bold">Utilizados: </span>
          {item.current_period_uses}
        </div>
        <div className="flex flex-row justify-between">
          <ButtonGroup variant="outlined" color="primary">
            <Button onClick={() => setIsEditModalOpen(true)}>Editar</Button>
          </ButtonGroup>
          <ButtonGroup variant="outlined" color="secondary">
            <Button onClick={() => setIsDeleteModalOpen(true)}>Eliminar</Button>
          </ButtonGroup>
        </div>
      </div>
    </Content>
  )
}

export default IncludedService;
