import { Button, ButtonGroup, Checkbox, FormControlLabel, Modal, Radio, RadioGroup, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import subscriptionsAPI from "../../utils/api/v2/subscriptions";
import { SpecificItem } from "../../utils/interfaces/Items";
import ModalContainer from "../Containers/ModalContainer";
import ItemsTable from "../Items/ItemsTable";
import LoadingError from "../Loaders/LoadingError";

type NewCouponType = {
  referrer_id: string;
  discount: number
  global_discount: boolean
  items?: string[];
}

type CouponType = {
  id: string;
  discount: number;
  global_discount: boolean;
  specific_items?: SpecificItem[];
}

type EditCouponModalProps = {
  isOpen: boolean;
  closeModal: (reload?: boolean) => void;
  subscriptionId: string;
  coupon: CouponType;
}

const EditCouponModal = ({ isOpen, closeModal, subscriptionId, coupon }: EditCouponModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [itemsWithName, setItemsWithName] = useState<string[]>([]);
  const [changes, setChanges] = useState<NewCouponType>({
    referrer_id: coupon.id,
    discount: coupon.discount,
    global_discount: coupon.global_discount,
  })

  const handleChangeDiscount = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = parseInt(e.target.value);
    setChanges({ ...changes, discount: value/100 });
  }

  const handleAdd = async () => {
    setLoading(true);
    try {
      const req = await subscriptionsAPI.editCoupon(subscriptionId, changes);
      closeModal(true);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  }

  const handleAddService = ({id, name}: SpecificItem) => {
    const existingItems = changes.items || [];
    existingItems.push(id)
    setChanges({ ...changes, items: existingItems });
    setItemsWithName([...itemsWithName,  name ])
  }

  useEffect(() => {
    const itemNames = coupon.specific_items?.map((item) => {
      return item.name
    })
    setItemsWithName(itemNames || [])
    const itemIds = coupon.specific_items?.map((item) => {
      return item.id
    })
    setChanges({ ...changes, items: itemIds })
  }, [coupon])

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        closeModal();
        document.getElementById("navbar").style.zIndex = "1999";
      }}
      style={{ height: "100%" }}
    >
      <ModalContainer>
        <LoadingError loading={loading} error={error} />
        {!loading && (
          <div className="flex flex-col gap-y-2">
            <div className="text-xl">Editar cupón</div>
            <TextField
              fullWidth
              type="number"
              label="Porcentaje de descuento"
              variant="standard"
              onChange={(e) => handleChangeDiscount(e)}
              className="w-56"
              value={changes.discount*100}
              InputProps={{
                inputProps: { min: 0, max: 100 }
              }}
            />
            <FormControlLabel
              label="Descuento global"
              control={
                <Checkbox
                  checked={changes.global_discount}
                  color="secondary"
                  checkedIcon={<MdRadioButtonChecked />}
                  icon={<MdRadioButtonUnchecked />}
                  onClick={() => {
                    setChanges({ ...changes, global_discount: !changes.global_discount, items: undefined });
                  }}
                />
              }
            />
            {!changes.global_discount && (
              <>
                {itemsWithName.map((item) => {
                    <div>{item}</div>
                })}
                <ItemsTable onClick={handleAddService}/>
              </>
            )}
          </div>
        )}
        <div className="flex flex-row justify-between mt-3">
          <ButtonGroup variant="outlined" color="primary">
            <Button onClick={() => handleAdd()}>Agregar</Button>
          </ButtonGroup>
          <ButtonGroup variant="outlined" color="primary">
            <Button onClick={() => closeModal()}>Cancelar</Button>
          </ButtonGroup>
        </div>
      </ModalContainer>
    </Modal>
  )
};

export default EditCouponModal;
